<template>
  <div class="page-wrapper">
    <div
      class="scroll-wrapper"
      id="scroll-wrapper"
      ref="scroll"
      :style="{ height: showNav ? 'calc(100vh - 46px)' : '100vh' }"
    >
      <ul class="data-list">
        <li
          class="data-item"
          @click="navToDetail(item)"
          v-for="(item, index) in dataList"
          :key="index"
        >
          <div class="data-title">
            <span>{{ item.itemName }}</span>
            <div style="display: flex; align-items: center" class="button-box">
              <van-button
                type="primary"
                round
                size="mini"
                @click.stop="handleReApply(item)"
                v-if="item.status == 7 || item.status == 8"
                style="margin-right: 0.2rem"
                class="button"
                >重新提交
              </van-button>
              <van-icon name="arrow" color="#999" />
            </div>
          </div>
          <div class="data-info">
            <div style="font-size: 13px">
              <span style="margin-right: 0.3rem"> {{ item.statusName }} </span>
              <span> 提交时间：{{ item.createTime }}</span>
            </div>
          </div>
        </li>
        <no-more v-if="noMore" />
      </ul>
      <van-empty v-if="noData" description="暂无数据" />
    </div>
  </div>
</template>

<script>
import { applyInfoPage } from "@/api/policy/apply";
import BScroll from "better-scroll";
import NoMore from "@/components/NoMore";
import { Dialog } from "vant";

export default {
  data() {
    return {
      activeTab: "0",
      tabs: [
        { name: "综合新闻", type: "0" },
        { name: "人才政策", type: "1" },
        { name: "公示公告", type: "2" },
        { name: "公开招聘", type: "3" },
      ],
      currentTab: 0,
      pageNum: 1,
      pageSize: 20,
      loading: false, //搜索状态
      noMore: false,
      noData: false,
      dataList: [],
      navHeight: 46,
      beforePullDown: true,
      isPullingDown: false,
      isPullUpLoad: false,
      bscroll: null,
    };
  },
  components: {
    NoMore,
  },
  watch: {
    activeTab() {
      this.initPageData();
      this.getList();
    },
  },
  computed: {
    showNav() {
      return this.$store.state.global.showNav;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initScroll();
    });
    this.getList();
  },

  methods: {
    initScroll() {
      this.bscroll = new BScroll(this.$refs.scroll, {
        pullUpLoad: true,
        pullDownRefresh: false,
        click: true,
      });
      this.bscroll.on("pullingUp", () => {
        this.handleScrollBottom();
      });
    },

    // handleRefresh() {
    //   this.initPageData();
    //   this.getList();
    //   this.bscroll.finishPullDown();
    // },
    //init
    initPageData() {
      this.pageNum = 1;
      this.loading = false;
      this.noData = false;
      this.noMore = false;
    },
    //滚到底
    handleScrollBottom() {
      if (this.loading || this.noMore) {
        return;
      }
      this.pageNum++;
      this.getList();
    },
    getList() {
      if (this.loading) return;
      this.loading = true;
      const params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      applyInfoPage(params)
        .then((res) => {
          this.loading = false;
          if (!res) {
            this.noMore = true;
            this.noData = true;
            return;
          }
          if (this.pageNum == 1) {
            this.dataList = res.data.records || [];
          } else {
            this.dataList = [...this.dataList, ...res.data.records];
          }
          //判断nomore
          if (res && res.data.pages == res.data.current) {
            this.noMore = true;
          } else {
            this.noMore = false;
          }

          if (res.data.total == 0) {
            this.noData = true;
          } else {
            this.noData = false;
          }
          this.$nextTick(() => {
            this.bscroll.finishPullUp();
            this.bscroll.refresh();
          });
        })
        .catch(() => {
          this.$nextTick(() => {
            this.loading = false;
          });
        });
    },
    navToDetail(e) {
      this.$router.push({
        path: `/applyDetail/${e.bizCode}`,
      });
    },
    handleReApply(e) {
      //1 -判断启用状态
      if ("1" != e.itemStatus) {
        this.$toast("当前办事项已暂停办理");
        return;
      }
      //2-判断线上办理还是线下办理
      if ("0" == e.online) {
        this.$toast("当前办事项不支持线上办理");
        return;
      }
      //3-判断网办时间
      if (3 == e.declareStatus) {
        this.$toast("当前办事项不在办事项办理时间以内");
        return;
      }

      //3-判断网办时间
      if (3 == e.declareStatus) {
        this.$toast("当前办事项不在办事项办理时间以内");
        return;
      }
      //判断企业办事
      if (-1 == e.serviceObject.indexOf("1")) {
        this.$toast("温馨提示：手机端暂不支持企业办事");
        return;
      }
      //4- 如果去外网办理需要直接跳走
      if (e.onlinePlate == 1) {
        //4.1判断跳转地址是否有值
        if (!e.phoneExternalUrl) {
          this.$toast("正在与其他平台对接中，敬请期待");
          return;
        }
        if (!/(^http:\/\/)|(^https:\/\/)/.test(e.phoneExternalUrl)) {
          Dialog.alert({
            message: `温馨提示：${e.phoneExternalUrl}`,
          });
          return;
        }
        window.location.href = e.phoneExternalUrl;
        return;
      }
      //5-如果本网站办理判断流程是否完备（新数据不会出现）
      if (1 == e.declareStatus) {
        this.$toast(
          "当前办事项已暂停办理，恢复办理时间和相关事宜可咨询业务受理部门。给您带来的不便，敬请谅解！"
        );
        return;
      }
      this.$router.push({
        path: `/apply/${e.itemId}?r=${e.bizCode}`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.page-wrapper {
  position: relative;
  background: #f8f8f8;
}
.scroll-wrapper {
  position: relative;
  height: 100%;
  overflow: hidden;
}
.loading {
  display: flex;
  padding: 0.2rem 0;
  align-items: center;
  justify-content: center;
}
.data-list {
  padding: 0.2rem 0.2rem 0;
  border-radius: 0.2rem;
  border-bottom: 1px solid transparent;
  .data-item {
    padding: 0.2rem 0;
    border-bottom: 1px solid #e5e5e5;
    &:nth-last-of-type(1) {
      border-bottom: none;
    }
  }
  .data-title {
    font-size: 0.32rem;
    color: #000000;
    line-height: 0.46rem;
    margin-bottom: 0.12rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .button-box {
      margin-left: 0.1rem;
      .button {
        width: 1rem;
      }
    }
  }
  .data-info {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 0.28rem;
    color: #999999;
    line-height: 0.4rem;
  }
}
</style>
